<template>
    <main>
        <HeaderTab :title="$t('global.titre_display_settings')" />
        <div id="content">
            <div class="container-fluid">
                <div class="box">
                    <label>
                        {{ $t('compte.lang.choice') }}
                    </label>
                    <e-select
                        v-if="currentLang"
                        v-model="currentLang"
                        :options="languages"
                        :allow-empty="false"
                        label="label"
                        track-by="lang"
                    >
                        <template slot="singleLabel">
                            <span class="ml-2">{{ currentLang.label }}</span>
                        </template>
                        <template slot="option" slot-scope="props">
                            <span class="ml-2">{{ props.option.label }}</span>
			            </template>
                    </e-select>

                    <div class="mt-4">
                        <b-button
                            variant="primary"
                            :disabled="processingLang"
                            @click="submitLanguage"
                        >
                            <font-awesome-icon v-if="processingLang" :icon="['fas', 'spinner']" pulse />
                            {{ $t("compte.lang.edit") }}
                        </b-button>
                    </div>
                </div>

				<div class="box">
                    <label>
                        {{ $t('compte.theme.title') }}
                    </label>
                    <e-select
                        v-model="theme"
                        :options="themes"
                        :allow-empty="false"
                        label="label"
                        track-by="code"
                    />

                    <div class="mt-4">
                        <b-button
                            variant="primary"
                            :disabled="processingTheme"
                            @click="submitTheme"
                        >
                            <font-awesome-icon v-if="processingTheme" :icon="['fas', 'spinner']" pulse />
                            {{ $t("compte.theme.edit") }}
                        </b-button>
                    </div>
                </div>

                <div class="box">
                    <label>
                        {{ $t('compte.lang.pays') }}
                    </label>
                    <div class="row mt-2">						
						<div class="col-sm-8"><CountryInput v-model="currentCountry"></CountryInput></div>
					</div>

                    <div class="mt-4">
                        <b-button
                            variant="primary"
                            :disabled="processingCountry"
                            @click="submitCountry"
                        >
                            <font-awesome-icon v-if="processingCountry" :icon="['fas', 'spinner']" pulse />
                            {{ $t("compte.lang.editCountry") }}
                        </b-button>
                    </div>
                </div>

                <div class="box">
                    <label>
                        {{ $t('compte.export.format') }}
                    </label>
                    <e-select
                        v-model="currentFormatExport"
                        :options="formats"
                        :allow-empty="false"
                        label="label"
                        track-by="code"
                    />
                    <div class="mt-4">
                        <b-button
                            variant="primary"
                            :disabled="processingFormatExport"
                            @click="submitFormatExport"
                        >
                            <font-awesome-icon v-if="processingCountry" :icon="['fas', 'spinner']" pulse />
                            {{ $t("compte.export.edit") }}
                        </b-button>
                    </div>
                </div>

                <div class="box">
                    <label>
                        {{ $t('global.format_date') }}
                    </label>
                    <e-select
                        v-model="currentFormatDate"
                        :options="formats_date"
                        :allow-empty="false"
                        label="label"
                        track-by="code"
                    />
                    <div class="mt-4">
                        <b-button
                            variant="primary"
                            @click="submitFormatDate"
                        >
                            <font-awesome-icon v-if="processingDate" :icon="['fas', 'spinner']" pulse />
                            {{ $t("compte.export.edit") }}
                        </b-button>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>

<script>
import Vue from 'vue'
import User from '@/mixins/User'
import Common from '@/assets/js/common.js'

export default {
    name: 'DisplaySettings',
    mixins: [User],
    data: () => ({
        languages: [],
        formats: [],
        currentFormatExport: "",
        currentLang: Vue.i18n.locale(),
        currentCountry: "75",
		processingLang: false,
		processingCountry: false,
		processingTheme: false,
        processingFormatExport: false,
		theme: null,
		themes: [],
        currentFormatDate: '',
        formats_date: [],
        processingDate: false
    }),
    created() {
        this.init_component()
    },
    methods: {
        init_component() {
            this.languages = []
            Vue.i18n.locales().forEach(lang => {
                const option = {
                    label: this.$t(`compte.lang.locales.${lang}`),
                    lang
                }
                this.languages.push(option)

                if (Vue.i18n.locale() === lang) {
                    this.currentLang = option
                }
			})

            this.currentCountry = this.getConfig('country', '75')

			this.themes = [
				{
					code: 'dark',
					label: this.$t('compte.theme.themes.dark')
				},
				{
					code: 'light',
					label: this.$t('compte.theme.themes.light')
				},
                {
                    code: 'contraste',
                    label: this.$t('compte.theme.themes.contraste')
                },
                // {
                //     code: 'gfe',
                //     label: this.$t('compte.theme.themes.gfe')
                // },
                // {
                //     code: 'monceaux',
                //     label: this.$t('compte.theme.themes.monceaux')
                // },
			]
			const themeCode = this.getConfig('theme', 'light')
			this.theme = this.themes.find((opt) => (opt.code === themeCode))
			
            this.formats = [
                {code: '.', label:  this.$t("compte.export.point")},
                {code: ',',label: this.$t("compte.export.virgule")}
            ]
			const FormatExport = this.getConfig('format_export', ',')
			this.currentFormatExport = this.formats.find((opt) => (opt.code === FormatExport))

            this.formats_date = Common.getAllDateFormat()

            let format_date = this.getConfig('format_date')
            if(!format_date) {
                format_date = 'd/m/Y'
            }
            this.currentFormatDate = this.formats_date.find(format => format.code == format_date)
        },
        
        async submitLanguage() {
            try {
                this.processingLang = true
                await this.setLanguage(this.currentLang.lang)
                await this.reloadDbConfig()
                await this.loadConfig()
            } catch (err) {
                console.error(err)
                this.failureToast()
            } finally {
                this.processingLang = false
            }
		},
        async submitCountry() {
            this.processingCountry = true
            this.setConfig('country', this.currentCountry)
            this.processingCountry = false
            this.successToast('toast.info_save_succes')
		},
        submitFormatExport() {
            this.processingFormatExport = true
            this.setConfig('format_export', this.currentFormatExport.code)
            this.processingFormatExport = false
            this.successToast('toast.info_save_succes')
		},
		submitTheme() {
			this.processingTheme = true
			this.setConfig('theme', this.theme.code)
            this.successToast('toast.info_save_succes')
			window.location.reload()
		},
        submitFormatDate() {
            this.processingDate = true
            this.setConfig('format_date', this.currentFormatDate.code)
            this.processingDate = false
            this.successToast('toast.info_save_succes')
        }
    },
    components: {
        HeaderTab: () => import('@/components/HeaderTab'),
        CountryInput : () => import('GroomyRoot/components/Inputs/CountryInput'),
    }
}
</script>